import { useContext, useEffect, useState } from 'react'
import { Space, Image, Row, Flex, Typography } from 'antd'
import { CheckpointProfileType, GenerateImg2ImgType, ImageType, NewCheckpointType } from '../../types'
import SystemPromptOptions from './components/SystemPromptOptions'
import RerenderImg2ImgView from './components/RerenderImg2ImgView'
import EditSystemPromptView from './components/EditSystemPromptView'
import ResultPhotosView from './components/ResultPhotosView'
import RenderFailView from './components/RenderFailView'
import useImg2ImgItem from '../../hooks/useImg2ImgItem'
import Constant from '../../controller/Constant'
import { ThreadImg2ImgHistoryType } from '../../types/thread'
import { AppContext } from '../../hooks/AppContext'

type Props = {
  checkpointProfile: CheckpointProfileType | undefined
  fileListBase64: ImageType[]
  isItemLast: boolean
  setJobRunning: (value: boolean) => void
  jobRunning: boolean
  isHistory?: boolean
  threadHistory?: ThreadImg2ImgHistoryType
  threadSelectedID: number
  blockIndex: number
  index: number
  setBlockIndex: (value: number) => void
}

const RenderFullFlowItem: React.FC<Props> = (props) => {
  const { windowWidth, isMobile } = useContext(AppContext)
  const [isRendering, setIsRendering] = useState(false)

  const {
    mediaProcessingJobID,
    getMediasProcessing,
    isStopLoop,
    systemPromptType,
    handleRequestImg2Img,
    isRequestedImg2Img,
    generatedImageList,
    isBlockSelectPrompt,
    setIsBlockSelectPrompt,
    onSelectEditSystemPrompt,
    isRenderFail,
    renderFailApiMsg,
    handleRequestReGenerate,
    checkIsDisabled,
    setMediaProcessingJobID
  } = useImg2ImgItem(
    props?.checkpointProfile,
    props?.fileListBase64,
    props?.setJobRunning,
    props?.jobRunning,
    props?.threadHistory,
    props?.threadSelectedID
  )

  const scrollToRequestProcessing = () => {
    setTimeout(() => {
      console.log('====== scroll to requestProcessing ======')
      window.scrollTo({
        top: document.getElementById('requestProcessing')?.offsetTop,
        behavior: 'smooth'
      })
    }, 1500)
  }

  const onClickRerenderFullFlow = () => {
    props.setJobRunning(true);
    handleRequestImg2Img(systemPromptType);
    scrollToRequestProcessing();
  }

  const checkUploadMultiImg = () => {
    if (props?.fileListBase64.length > 1) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (props?.threadHistory?.status == 1) {
      setMediaProcessingJobID(props?.threadHistory.id)
    }
  }, [])

  useEffect(() => {
    if (props?.jobRunning) {
      setIsBlockSelectPrompt(true)
    }
    setIsRendering(props?.jobRunning)
  }, [props?.jobRunning])

  useEffect(() => {
    if (mediaProcessingJobID) {
      props?.setJobRunning(true)
      const timerId = setInterval(() => {
        getMediasProcessing(mediaProcessingJobID)
      }, 5000)

      if (isStopLoop) {
        clearInterval(timerId)
        setIsRendering(false)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [mediaProcessingJobID, isStopLoop])

  return (
    <Flex
      vertical
      style={{
        width: windowWidth * (605 / 1440)
      }}
    >
      <SystemPromptOptions
        systemPromptType={systemPromptType}
        handleRequestImg2Img={(value) => {
          handleRequestImg2Img(value)
          props.setBlockIndex(props.index +1)
          setIsRendering(true)
          console.log('========== Render Full Flow Item ==========')
          scrollToRequestProcessing()
        }}
        isBlockSelectPrompt={isBlockSelectPrompt}
      />
      <Flex align='center' className='mt-3'>
        <img src={Constant.icon.bot} className='h-[44px] aspect-square mr-5' />
        <Typography.Text strong className='text-[20px]'>
          画像のアップロードをしてください
        </Typography.Text>
      </Flex>
      <Typography.Text className='ml-14 mt-5'>・画像データ10枚までアップロード出来ます。</Typography.Text>
      <Space className=' ml-[65px] flex-wrap mt-8'>
        {props?.fileListBase64?.map((item: ImageType) => (
          <Image
            src={item?.url}
            height={(windowWidth * (checkUploadMultiImg() ? 150 : 350)) / 1440}
            className='aspect-square object-cover rounded-lg'
          />
        ))}
      </Space>
      {(isRequestedImg2Img || props?.threadHistory) && (
        <>
          <EditSystemPromptView
            systemPromptType={systemPromptType}
            onSelectEditSystemPrompt={onSelectEditSystemPrompt}
            mediaProcessingJobID={mediaProcessingJobID}
          />
          {generatedImageList?.map((item: GenerateImg2ImgType, index: number) => (
            <ResultPhotosView
              key={item?.id}
              mediaProcessingJobID={mediaProcessingJobID}
              item={item}
              index={index + 1}
              onReGenerateImg={(promptID, prompt, batchSize) => {
                handleRequestReGenerate(promptID, batchSize)
                scrollToRequestProcessing()
                props.setBlockIndex(props.index +1)
              }}
              checkIsDisabled={checkIsDisabled}
            />
          ))}
          {isRendering && props.blockIndex === props.index + 1 ? (
            <div id='requestProcessing'>
              <ResultPhotosView
                isProcessing={true}
                index={generatedImageList?.length + 1}
                mediaProcessingJobID={0}
                onReGenerateImg={(promptID, prompt, batchSize) => {
                  handleRequestReGenerate(promptID, batchSize)
                  props.setBlockIndex(props.index +1)
                }}
                checkIsDisabled={checkIsDisabled}
              />
            </div>
          ) : (
            <div />
          )}
          {isRenderFail && <RenderFailView renderFailApiMsg={renderFailApiMsg} />}
        </>
      )}

      {props?.isItemLast && !props.jobRunning && systemPromptType ? (
        <RerenderImg2ImgView jobRunning={props?.jobRunning} onClickRerenderFullFlow={onClickRerenderFullFlow} />
      ) : (
        <div />
      )}
      {!props?.isItemLast && <Row className='h-[1px] bg-[#D3D3D3] my-[45px]' />}
    </Flex>
  )
}

export default RenderFullFlowItem
