import { createContext, useEffect, useState } from 'react'
import { notification } from 'antd'
import type { NotificationPlacement } from 'antd/es/notification/interface'
import { CheckCircleOutlined } from '@ant-design/icons'
import CopyAlert from '../screens/productContent/components/CopyAlert'
interface contextProps {
  isSpinning: boolean
  windowWidth: number
  windowHeight: number
  setIsSpinning: (value: boolean) => void
  menuSelected: number
  setMenuSelected: (value: number) => void
  showErrorApiAlert: (value: any) => void
  contextHolder: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  isMobile: boolean
  showProcessingAlert: (value: string) => void
  showGenerateCompleted: (title: string) => void
  showCopyCompleted: () => void
}

export const AppContext = createContext({} as contextProps)

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSpinning, setIsSpinning] = useState<boolean>(false)
  const [menuSelected, setMenuSelected] = useState<number>(0)
  const [api, contextHolder] = notification.useNotification()
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const showErrorApiAlert = (errorMsg: any, placement: NotificationPlacement = 'topRight') => {
    api.error({
      message: (
        <AppContext.Consumer>{() => `${errorMsg?.response?.data?.message ?? errorMsg?.message ?? errorMsg}`}</AppContext.Consumer>
      ),
      placement
    })
  }

  const showProcessingAlert = (text: string, placement: NotificationPlacement = 'top') => {
    notification.open({
      message: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleOutlined style={{ fontSize: 30, color: '#2CD003', marginRight: 20 }} />
          <div>{text}</div>
        </div>
      ),
      placement,
      duration: 10,
      style: {
        whiteSpace: 'pre-line',
        zIndex: 999,
        width: 520,
        paddingTop: 10,
        paddingBottom: 0
      },
      closeIcon: false
    })
  }

  const showGenerateCompleted = (
    title: string = '画像の生成が完了しました。AI画像一覧で確認してください。',
    placement: NotificationPlacement = 'top'
  ) => {
    notification.open({
      message: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleOutlined style={{ fontSize: 30, color: '#2CD003', marginRight: 20 }} />
          <div>{title}</div>
        </div>
      ),
      placement,
      style: {
        whiteSpace: 'pre-line',
        zIndex: 999,
        width: 520,
        paddingTop: 10,
        paddingBottom: 0
      },
      closeIcon: false,
      duration: 10
    })
  }

  const showCopyCompleted = () => {
    notification.open({
      message: <div style={{ color: 'white' }}>コピーしました</div>,
      description: null,
      placement: 'top',
      closeIcon: null,
      icon: <CheckCircleOutlined style={{ color: 'white' }} />,
      style: {
        height: 64,
        background: '#323539',
        borderRadius: 6
      },
      duration: 2
    })
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setIsMobile(windowWidth <= 850)
  }, [windowWidth])

  return (
    <AppContext.Provider
      value={{
        isSpinning,
        setIsSpinning,
        menuSelected,
        setMenuSelected,
        showErrorApiAlert,
        contextHolder,
        isMobile,
        showProcessingAlert,
        windowWidth,
        windowHeight,
        showGenerateCompleted,
        showCopyCompleted
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
