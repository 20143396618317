import axios from 'axios'

export default class BaseAPIs {
  static baseURL = 'https://cla-company-1.creativelink.ai' //PROD Auto scale
    //static baseURL = 'http://127.0.0.1:8000'
  // static baseURL = 'https://snafty-jewelry-be.test-development.work' //PROD
  //static baseURL = 'https://cla-dev.test-development.work' //DEV

  static headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json'
  }
}
