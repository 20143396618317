import { DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, ConfigProvider, Flex, Image, Input, Row, Select, Space, Spin, Typography, message } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import Constant from '../../../controller/Constant'
import { GenerateImg2ImgType } from '../../../types'
import PreviewImagesModal from './PreviewImagesModal'
import { AppContext } from '../../../hooks/AppContext'
import { url } from 'inspector'

type Props = {
  mediaProcessingJobID: number
  item?: GenerateImg2ImgType
  isProcessing?: boolean
  index: number
  onReGenerateImg: (promptID: number, prompt: string, batchSize: number) => void
  checkIsDisabled: () => boolean
}

const ResultPhotosView = (props: Props) => {
  const { mediaProcessingJobID, item, isProcessing = false, index = 1, onReGenerateImg, checkIsDisabled } = props
  const { windowWidth } = useContext(AppContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const [batchSize, setBatchSize] = useState(Constant.batchSizeOption[0])
  const [promptContent, setPromptContent] = useState<string>(item?.prompt ?? '')
  const [editedPrompt, setEditedPrompt] = useState<string>(item?.prompt ?? '')
  const [isPreview, setIsPreview] = useState(false)
  const [indexPreview, setIndexPreview] = useState<number>(0)

  const handleConfirmEdit = () => {
    message.success('保存しました。')
    setEditedPrompt(promptContent)
    setIsExpanded(false)
  }

  const handleOpen = () => setIsPreview(true)

  useEffect(() => {
    if (item?.images) {
      setBatchSize(Constant.batchSizeOption[item ? item?.images?.length - 1 : 0])
    }
  }, [item?.images])

  return (
    <Col className='mt-[35px]'>
      <Row className='items-center'>
        <Flex
          className='cursor-pointer mb-8'
          onClick={() => (item?.prompt ? setIsExpanded(!isExpanded) : undefined)}
          align='center'
          justify='flex-start'
        >
          <img src={Constant.icon.bot} className='h-[44px] aspect-square mr-5' />
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              marginRight: 12
            }}
          >
            {`タイトル${index}：${item?.title ?? ''}`}
          </Typography>
          {item?.prompt && (
            <>{isExpanded ? <UpOutlined className='cursor-pointer' /> : <DownOutlined className='cursor-pointer' />}</>
          )}
        </Flex>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        {isExpanded && (
          <Flex vertical justify='center' align='center'>
            <Input.TextArea
              className='mb-4 text-[16px]'
              value={promptContent}
              onChange={(e) => setPromptContent(e.target.value)}
              style={{
                width: windowWidth * (500 / 1440),
                overflow: 'scroll',
                scrollbarWidth: 'none'
              }}
              autoSize={{ minRows: 5, maxRows: 5 }}
            />
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorPrimaryHover: 'white'
                  }
                }
              }}
            >
              <Button
                onClick={handleConfirmEdit}
                className='bg-[#323539] mb-5 text-[16px] w-[190px] h-12 font-bold text-white '
                disabled={checkIsDisabled()}
              >
                編集を保存
              </Button>
            </ConfigProvider>
          </Flex>
        )}
      </Row>

      {isProcessing && (
        <Flex justify='center'>
          <Spin spinning={true} indicator={<LoadingOutlined className='text-[14px]' />}>
            <div className='bg-gray-500 aspect-square rounded-lg' style={{ height: windowWidth * (200 / 1440) }} />
          </Spin>
        </Flex>
      )}

      {item?.images && item?.images?.length > 0 && (
        <Space className='justify-center flex mb-5 flex-wrap '>
          {item?.images?.map((item: { url: string }, index: number) => (
            <img
              key={`${item?.url}-${index}`}
              src={item?.url}
              height={windowWidth * (200 / 1440)}
              onClick={() => {
                setIndexPreview(index)
                handleOpen()
              }}
              className='cursor-pointer aspect-square object-cover rounded-lg'
            />
          ))}
        </Space>
      )}
      {!isProcessing && item?.images && item?.images?.length > 0 && (
        <Row style={{ justifyContent: 'center' }}>
          <Flex justify='center' align='center'>
            <Typography className='mr-1 font-bold'>画像作成</Typography>
            <Select
              value={batchSize?.value}
              onChange={(value: any) => setBatchSize(Constant.batchSizeOption[value - 1])}
              disabled={checkIsDisabled()}
              bordered={false}
            >
              {Constant.batchSizeOption.map((item: any) => (
                <Select.Option key={item.value} value={item?.value}>
                  <p className='text-[12px] font-medium'>{`${item?.name}枚`}</p>
                </Select.Option>
              ))}
            </Select>
          </Flex>

          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimaryHover: 'white'
                }
              }
            }}
          >
            <Button
              className='bg-[#323539] text-white font-bold '
              onClick={() => {
                onReGenerateImg(item?.id ?? 0, editedPrompt, batchSize?.value)
                setPromptContent(item?.prompt ?? '')
                setIsExpanded(false)
              }}
              style={{
                height: 40,
                width: 256,
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 10,
                borderRadius: 7
              }}
              disabled={checkIsDisabled()}
            >
              作成する
            </Button>
          </ConfigProvider>
        </Row>
      )}
      <PreviewImagesModal
        imageList={item?.images ?? []}
        indexPreview={indexPreview}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
      />
    </Col>
  )
}

export default ResultPhotosView
