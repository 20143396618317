import BaseAPIs from './BaseAPI'
import axios from 'axios'

export default class Txt2ImgAPIs extends BaseAPIs {
  static endpoints = {
    loras: this.baseURL + '/train-lora/lora/loras-render',
    txt2img: this.baseURL + '/ai-img/description',
    txt2imgV2: this.baseURL + '/ai-img/text-to-image-v2',
    job: this.baseURL + '/ai-img/job-data'
  }

  static getLoras() {
    const token = localStorage.getItem('token')

    return axios
      .get(this.endpoints.loras, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      .then((result) => {
        if (result.data.code === 200) {
          return Promise.resolve(result.data.data)
        }

        return Promise.reject(result)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static sendApiTxt2img(params: any) {
    console.log('6666888888: ', params)

    const token = localStorage.getItem('token')

    const formData = new FormData()
    formData.append('category', params.category)
    formData.append('batch_size', params.batch_size)
    formData.append('description', params.description)
    //params.loras.map((lora: string, index: number) => {
    //  formData.append(`loras[${index}]`, lora)
    //})

    return axios
      .post(this.endpoints.txt2imgV2, formData, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      .then((result) => {
        if (result.data.code === 200) {
          return Promise.resolve(result.data.data)
        }

        return Promise.reject(result)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static checkJobStatus(jobID: number) {
    const token = localStorage.getItem('token')

    return axios
      .get(this.endpoints.job + `/${jobID}/status`, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      .then((result) => {
        if (result.data.code === 200) {
          return Promise.resolve(result.data.data)
        }

        return Promise.reject(result)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}
