import { Flex, Select } from 'antd'

type Prop = {
  setBatchSize: (val: string) => void
}

export default function BatchSize(prop: Prop) {
  return (
    <div className='w-[256px] mt-auto'>
      <Flex className='w-full mt-7 flex justify-center content-center'>
        <span className='self-center font-bold'>画像作成</span>
        <Select
          className='w-15 self-center ml-4 [&>div]:border-8'
          defaultValue='1'
          onChange={(val) => prop.setBatchSize(val)}
          options={[
            { value: '1', label: '1セット' },
            { value: '2', label: '2セット' },
            { value: '3', label: '3セット' },
            { value: '4', label: '4セット' }
          ]}
        />
      </Flex>
      <p className='text-[10px] text-[#CCCCCC] mt-5 pr-[25px]'>
        ※入力に基づき、3種類の画像が各1セット3枚 <br />
        生成されます。<br />
        例：3セットで合計9枚。
      </p>
    </div>
  )
}
