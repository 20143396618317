import React, { useContext, useRef, useState } from 'react'
import { CloseCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Flex, Input, Layout, Modal, Popconfirm, Select, Tooltip, Typography } from 'antd'
import { Outlet, Navigate, Link, useNavigate, useLocation } from 'react-router-dom'
import logo from '../../assets/logo.png'
import ArrorLeftIcon from '../../assets/icons/arrow-left.svg'
import { useThreadStore } from '../../controller/store'
import { IThreadList } from '../../types/thread'
import ClickAwayListener from 'react-click-away-listener'
import Constant from '../../controller/Constant'
import Img2ImgAPIs from '../../controller/API/Img2ImgAPIs'
import { AppContext } from '../../hooks/AppContext'
import HeaderCountdown from '../../components/common/HeaderCountdown'
import BeforeUnloadModal from '../../components/BeforeUnloadModal'
import PermissionPopup from '../../components/Auth/PermissionPopup'

const { Header } = Layout
export default function DetailLayout() {
  const navigate = useNavigate()
  const {
    threadList,
    screenKey,
    setThreadSelectedID,
    setThreadList,
    threadSelectedID,
    countNewThread,
    setCountNewThread
  } = useThreadStore()
  const { showErrorApiAlert } = useContext(AppContext)

  const [isVisible, setVisible] = useState<boolean>(false)
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false)
  const [selectedTooltip, setSelectedTooltip] = useState<number>(0)
  const [isVisibleThreadNameModal, setIsVisibleThreadNameModal] = useState<boolean>(false)
  const [editThreadSelected, setEditThreadSelected] = useState<IThreadList>({ id: 0, name: '', thumbnail: '' })
  const [threadSelected, setThreadSelected] = useState<IThreadList | undefined>()
  const beforeUnloadModalRef: any = useRef()

  const openSelect = () => {
    setIsOpenTooltip(false)
    setTimeout(() => {
      setVisible(!isVisible)
    }, 100)
  }

  const closeSelect = () => {
    if (editThreadSelected?.id) {
      setEditThreadSelected({ id: 0, name: '', thumbnail: '' })
    }
    setTimeout(() => {
      setIsOpenTooltip(false)
    }, 100)
    setTimeout(() => {
      setVisible(false)
    }, 200)
  }

  const isImg2ImgScreen = () => {
    if (screenKey == Constant.keyScreen.img2img) {
      return true
    }
    return false
  }

  const checkNameScreen = () => {
    switch (screenKey) {
      case Constant.keyScreen.trainLora:
        return 'ブランド学習'
      case Constant.keyScreen.img2img:
        return '参考画像からデザイン提案'
      case Constant.keyScreen.txt2img:
        return 'テキストからデザイン提案'
      case Constant.keyScreen.changeMaterial:
        return '生地シミュレーション'
      case Constant.keyScreen.createProductContent:
        return '商品説明文生成'
      case Constant.keyScreen.controlGpu:
        return 'Control GPU'
      default:
        return ''
    }
  }

  const checkDataFlowScreen = () => {
    switch (screenKey) {
      case Constant.keyScreen.img2img:
        return threadList
      default:
        return []
    }
  }

  const handleSelectThread = (threadID: number) => {
    closeSelect()
    setThreadSelectedID(threadID)
    window.scrollTo(0, 0)
  }

  const handleEditThreadName = () => {
    Img2ImgAPIs.editThreadName(editThreadSelected?.id, editThreadSelected?.name)
      .then((res) => {
        const newDt = threadList?.map((item: IThreadList) => {
          return {
            ...item,
            name: item?.id == editThreadSelected?.id ? editThreadSelected?.name : item?.name
          }
        })
        setThreadList(newDt)
        setIsVisibleThreadNameModal(false)
        setEditThreadSelected({ id: 0, name: '', thumbnail: '' })
      })
      .catch((err) => {
        showErrorApiAlert(err)
      })
  }

  const handleCreateNewThread = () => {
    setCountNewThread(countNewThread + 1)
    closeSelect()
    setThreadSelected(undefined)
  }

  const onGoHome = () => {
    handleCreateNewThread()
    setTimeout(() => {
      beforeUnloadModalRef.current.navigateWithBeforeUnload('/')
    }, 100)
  }

  const renderTooltip = (thread: IThreadList) => {
    const handleGoThread = () => {
      handleSelectThread(thread?.id)
    }

    const handleDeleteThread = () => {
      const newDt: IThreadList[] = threadList?.filter((item) => item.id != thread?.id)
      setThreadList(newDt)
      setIsOpenTooltip(false)
      Img2ImgAPIs.deleteThread(thread?.id)
        .then((res) => {
          if (thread?.id == threadSelectedID) {
            handleCreateNewThread()
          }
        })
        .catch((err) => showErrorApiAlert(err))
    }

    const onSetEditThreadName = () => {
      setEditThreadSelected(thread)
      setIsVisibleThreadNameModal(true)
      setIsOpenTooltip(false)
    }

    return (
      <Flex vertical className='px-2' justify='center'>
        <Flex onClick={handleGoThread} className='cursor-pointer py-3'>
          <img className='w-6 h-6 mr-4' src={Constant.icon.create} />
          <Typography.Text strong>プロジェクトを開く</Typography.Text>
        </Flex>
        <Flex onClick={onSetEditThreadName} className='cursor-pointer py-3'>
          <img className='w-6 h-6 mr-4' src={Constant.icon.edit} />
          <Typography.Text strong>名前を変更する</Typography.Text>
        </Flex>
        <Popconfirm
          title='このプロジェクトを削除しますか？'
          open={undefined}
          defaultOpen={false}
          onConfirm={handleDeleteThread}
          okText='削除する'
          cancelText='キャンセル'
          okButtonProps={{ style: { backgroundColor: '#323539', color: 'white' } }}
        >
          <Flex className='cursor-pointer py-3'>
            <img className='w-6 h-6 mr-4' src={Constant.icon.delete} />
            <Typography.Text strong className='text-red-500 '>
              削除する
            </Typography.Text>
          </Flex>
        </Popconfirm>
      </Flex>
    )
  }

  return (
    <Layout>
      <Header className='bg-white mb-2 h-[70px] text-[18px] font-bold flex fixed w-full top-0 z-30 pl-5'>
        <a className='flex-[2] flex items-center' onClick={onGoHome}>
          <img src={logo} className='w-12 h-12 p-2' />
        </a>
        <Flex className='flex flex-[3] items-center text-black cursor-pointer'>
          <a onClick={onGoHome} className='text-xs text-black font-medium mr-2 hover:text-[#3b82f680]'>
            ホーム
          </a>
          <Flex>
            <img src={ArrorLeftIcon} className='cursor-pointer mr-2' />
          </Flex>

          <div className='text-xs font-medium'>{checkNameScreen()}</div>
        </Flex>
        <ClickAwayListener onClickAway={closeSelect}>
          <div className='flex flex-[3] items-center justify-center'>
            {isImg2ImgScreen() && (
              <Flex align='center' justify='center'>
                <Flex justify='center' align='center' className='cursor-pointer absolute z-50 ' onClick={openSelect}>
                  <div className='text-xs  mr-2 text-[13px] font-bold'>
                    {threadSelected == undefined ? '新しいプロジェクト' : threadSelected?.name}
                  </div>
                  <img src={Constant.icon.arrow} className='w-6 aspect-square' />
                </Flex>
                <Select
                  className='w-[320px]'
                  value=''
                  bordered={false}
                  suffixIcon={false}
                  open={isVisible}
                  listHeight={260}
                >
                  <Select.Option>
                    <Flex justify='space-between' align='center' onClick={handleCreateNewThread} className='py-2'>
                      <Flex onClick={() => setIsOpenTooltip(false)} justify='center' align='center'>
                        <Flex className='h-[60px] aspect-square' justify='center' align='center'>
                          <img src={Constant.icon.bot} className='h-[44px] aspect-square' />
                        </Flex>
                        <Typography className='ml-3'>新しいプロジェクトを作成する</Typography>
                      </Flex>
                    </Flex>
                  </Select.Option>
                  {checkDataFlowScreen()?.map((item: IThreadList) => {
                    return (
                      <Select.Option key={item?.id} value={item.id}>
                        <Flex justify='space-between' align='center' className='py-2'>
                          <Flex
                            onClick={() => {
                              setThreadSelected(item)
                              if (editThreadSelected.id != item?.id) {
                                handleSelectThread(item?.id)
                              }
                            }}
                            justify='center'
                            align='center'
                          >
                            <img src={item?.thumbnail} className='h-[60px] aspect-square object-cover ' />
                            {/* {editThreadSelected.id == item?.id ? (
                              <Input
                                value={editThreadSelected.name}
                                onChange={(text) =>
                                  setEditThreadSelected((prev: IThreadList) => {
                                    return { ...prev, name: text.target.value }
                                  })
                                }
                              />
                            ) : ( */}
                            <Typography className='ml-3 overflow-hidden whitespace-nowrap text-ellipsis w-[170px]'>
                              {item?.name}
                            </Typography>
                            {/* )} */}
                          </Flex>
                          <Tooltip
                            title={() => renderTooltip(item)}
                            placement='bottomLeft'
                            color='white'
                            arrow={false}
                            open={selectedTooltip == item?.id ? isOpenTooltip : false}
                            trigger='click'
                            fresh={true}
                          >
                            <img
                              src={Constant.icon.threeDot}
                              className='w-6 h-6'
                              onClick={() => {
                                setIsOpenTooltip(!isOpenTooltip)
                                setSelectedTooltip(item?.id)
                              }}
                            />
                          </Tooltip>
                        </Flex>
                      </Select.Option>
                    )
                  })}
                </Select>
              </Flex>
            )}
            <Modal
              title='プロジェクト名を変更する'
              open={isVisibleThreadNameModal}
              onOk={handleEditThreadName}
              onCancel={() => setIsVisibleThreadNameModal(false)}
              cancelText='キャンセル'
              okText='保存する'
              zIndex={9999}
              closeIcon={<CloseCircleOutlined className='text-black' />}
              okButtonProps={{ style: { backgroundColor: '#323539', color: 'white' } }}
            >
              <Input.TextArea
                value={editThreadSelected.name}
                onChange={(text) =>
                  setEditThreadSelected((prev: IThreadList) => {
                    return { ...prev, name: text.target.value }
                  })
                }
                style={{
                  resize: 'none',
                  height: 80,
                  scrollbarWidth: 'none'
                }}
              />
            </Modal>
          </div>
        </ClickAwayListener>
        <div className='flex flex-[4] ' />
        <div className='flex justify-center'>
          <HeaderCountdown isShowTime={false} />
        </div>
      </Header>
      <Layout className='mt-[75px]'>
        {localStorage.getItem('token') ? <Outlet /> : <Navigate to='/login' replace />}
      </Layout>
      <BeforeUnloadModal ref={beforeUnloadModalRef} />
      <PermissionPopup />
    </Layout>
  )
}
