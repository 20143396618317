import { Button, Col, Image, Row, Typography } from 'antd'
import React, { useContext } from 'react'
import Constant from '../../../controller/Constant'
import { AppContext } from '../../../hooks/AppContext'

type Props = {
  onClickRerenderFullFlow: () => void
  jobRunning: boolean
}

const RerenderImg2ImgView = (props: Props) => {
  const { jobRunning } = props
  const { isMobile } = useContext(AppContext)

  return (
    <Col className='mb-7 mt-[40px]'>
      <Row style={{ alignItems: 'center' }}>
        <img src={Constant.icon.bot} className='h-[44px] aspect-square mr-5' />
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 'bold'
          }}
        >
          他のリクエストのご希望はありますか？
        </Typography>
      </Row>

      <Col style={{ marginLeft: isMobile ? 0 : 65, marginTop: 10 }}>
        <Row>
          <Typography style={{ fontSize: 14, fontWeight: 'normal', textAlign: 'left', whiteSpace: 'pre-line' }}>
            {`・もう一度タイトルを生成したい場合は「もう一度タイトル生成する」ボタンを押してください
            ・別の画像で画像生成する場合は画像をアップロードしてください。`}
          </Typography>
        </Row>
        <Row style={{ justifyContent: 'center', marginTop: 40 }}>
          <Button
            type='primary'
            onClick={() => {
              props.onClickRerenderFullFlow();
            }}
            style={{
              background: jobRunning ? '#c4c4c4' : '#323539',
              borderWidth: 1,
              borderRadius: 8,
              width: 256,
              fontWeight: 'bold',
              whiteSpace: 'pre-line',
              color: 'white',
              height: 60
            }}
            disabled={jobRunning}
          >
            {`もう一度\nタイトル生成する`}
          </Button>
        </Row>
      </Col>
    </Col>
  )
}

export default RerenderImg2ImgView
