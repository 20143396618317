import { NewCheckpointType } from '../../types'
import { Flex, Typography } from 'antd'
import Constant from '../../controller/Constant'

type Props = {
  data: NewCheckpointType
  activeCheckpoint?: any
  onSelect?: (data: any) => void
  style?: any
  onOpenReselect?: () => void
}

export default function CheckpointItem(props: Props) {
  const { data, onOpenReselect, onSelect, style, activeCheckpoint } = props
  const activeStyle: any = {}
  if (activeCheckpoint && activeCheckpoint.name == data.name) {
    activeStyle.border = 'solid 1px black'
  }
  return (
    <Flex
      align='center'
      className='w-[256px] bg-[#F6F6F6] rounded-lg p-2 relative flex cursor-pointer border-solid border-transparent mb-2 mr-5'
      style={{ ...style, ...activeStyle }}
      onClick={(e) => onSelect && onSelect(data)}
    >
      <img
        className='w-[60px] h-[60px] '
        src={props.data?.first_image == '' ? 'https://via.placeholder.com/300x200.png' : props.data?.first_image}
        style={{ objectFit: 'contain' }}
      />
      <Flex vertical justify='center' className='flex flex-col ml-2'>
        {/*<div className='text-[10px] font-light'>2024/06/04</div>*/}
        {/* <div className='text-xs font-normal'>{props.data.name}</div> */}
        <Typography
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
          className='mr-7'
        >
          {props.data?.name ? props.data?.name : ''}
        </Typography>
      </Flex>
      {onOpenReselect && (
        <img
          src={Constant.icon.exchange}
          className='absolute right-0 top-1/2 transform -translate-y-1/2 text-2xl cursor-pointer w-6 aspect-square object-contain mr-1'
          onClick={(e) => {
            e.stopPropagation()
            onOpenReselect()
          }}
        />
      )}
    </Flex>
  )
}
