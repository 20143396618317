import { Layout } from 'antd/lib'
const { Content } = Layout

export default function DefaultContent() {
  return (
    <>
      <div className=' w-full flex flex-col mt-[15%] content-center'>
        <img src={require('../../../assets/bot.png')} alt='AI bot' className='w-[120px] h-[120px] self-center' />
        <span className='self-center text-center font-bold text-[16px] mt-3'>
          生成カテゴリーを選択して、
          <br />
          生成したいイメージを入力しましょう
        </span>
      </div>
    </>
  )
}
