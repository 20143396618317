export default {
  color: {},

  nameScreen: {
    txt2img: 'txt2img',
    img2img: 'img2img',
    renderHistory: 'renderHistory'
  },
  keyScreen: {
    txt2img: 1,
    img2img: 2,
    trainLora: 3,
    renderHistory: 4,
    changeMaterial: 5,
    home: 6,
    createProductContent: 7,
    controlGpu: 8
  },

  icon: {
    bot: require('../assets/ic_bot.png'),
    create: require('../assets/ic_create.png'),
    edit: require('../assets/ic_edit.png'),
    delete: require('../assets/ic_delete.png'),
    threeDot: require('../assets/ic_three_dot.png'),
    closeTransparent: require('../assets/ic_close_transperent.png'),
    arrow: require('../assets/ic_arrow.png'),
    exchange: require('../assets/ic_exchange.png')
  },
  image: {},
  renderType: {
    hand: 'hand',
    background: 'background'
  },
  loraType: {
    AI: 2,
    normal: 1,
    txt2Img: 3,
    img2Img: 4,
    fixHandTxt2Img: 5,
    fixHandImg2Img: 6,
    fixBGImg2Img: 6
  },
  mediaType: {
    AI: 2,
    normal: 1,
    txt2Img: 3,
    img2Img: 4,
    fixHandTxt2Img: 5,
    fixHandImg2Img: 6,
    fixBGImg2Img: 6
  },
  mediaProcessing: {
    PROCESSING: 1,
    SUCCESS: 2,
    FAIL: 3
  },
  systemPromptType: {
    option1: 1,
    option2: 2,
    option3: 3,
    option4: 4
  },
  batchSizeOption: [
    {
      value: 1,
      name: '1'
    },
    {
      value: 2,
      name: '2'
    },
    {
      value: 3,
      name: '3'
    },
    {
      value: 4,
      name: '4'
    }
  ],
  batchSizeImg2Img: [1, 2, 3, 4],
  selectCheckpointGuideText: [
    'カ |',
    'カテ |',
    'カテゴ |',
    'カテゴリ |',
    'カテゴリー |',
    'カテゴリーと |',
    'カテゴリーと画 |',
    'カテゴリーと画像 |',
    'カテゴリーと画像生 |',
    'カテゴリーと画像生成 |',
    'カテゴリーと画像生成枚 |',
    'カテゴリーと画像生成枚数 |',
    'カテゴリーと画像生成枚数を |',
    'カテゴリーと画像生成枚数を設 |',
    'カテゴリーと画像生成枚数を設定 |',
    'カテゴリーと画像生成枚数を設定し |',
    'カテゴリーと画像生成枚数を設定して |',
    'カテゴリーと画像生成枚数を設定してく |',
    'カテゴリーと画像生成枚数を設定してくだ |',
    'カテゴリーと画像生成枚数を設定してくださ |',
    'カテゴリーと画像生成枚数を設定してください'
  ],
  localStorageKeys: {
    isHideLoraIntro: 'is_hide_lora_intro',
    isHideImg2ImgIntro: 'is_hide_img2img_intro',
    isHideTxt2ImgIntro: 'is_hide_txt2img_intro',
    isHideChangeFabricIntro: 'is_hide_change_fabric_intro'
  },
  functionType: {
    trainLora: 1,
    txt2img: 2,
    img2img: 3,
    changeFabric: 4
  }
}
