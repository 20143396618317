import { useCallback, useContext, useState } from 'react'
import { message } from 'antd'
import { AppContext } from './AppContext'
import CommonAPIs from '../controller/API/CommonAPIs'
import { GenerateImg2ImgType, ImageType, RequestImg2ImgType } from '../types/media_type'
import { useThreadStore } from '../controller/store'
import { UploadChangeParam } from 'antd/es/upload'
import { RcFile } from 'antd/lib/upload'
import Img2ImgAPIs from '../controller/API/Img2ImgAPIs'
import { IThreadList } from '../types/thread'

const MAX_UPLOAD_PICTURE = 10

type RenderImg2ImgArray = {
  images: ImageType[]
}

const useNewThreadImg2Img = (jobRunning: boolean, setJobRunning: (value: boolean) => void) => {
  const {
    checkpointImg2ImgSelected,
    batchSizeSelected,
    setThreadList,
    setNewThreadID,
    newThreadID,
    checkpointProfile
  } = useThreadStore()
  const { showErrorApiAlert, showProcessingAlert } = useContext(AppContext)
  const [generatedImageList, setGeneratedImageList] = useState<GenerateImg2ImgType[]>([])
  const [mediaProcessingJobID, setMediaProcessingJobID] = useState<number>(0)
  const [isStopLoop, setStopLoop] = useState(false)
  const [systemPromptType, setSystemPromptType] = useState<number>(0)
  const [isRequestedImg2Img, setIsRequestedImg2Img] = useState<boolean>(false)
  const [imgUploaded, setImgUploaded] = useState<boolean>(false)
  const [isBlockSelectPrompt, setIsBlockSelectPrompt] = useState<boolean>(false)
  const [isShowProfileModal, setIsShowProfileModal] = useState(false)
  const [isRenderFail, setRenderFail] = useState<boolean>(false)
  const [renderFailApiMsg, setRenderFailApiMsg] = useState<string>('')
  const [fileListBase64NewThread, setFileListBase64NewThread] = useState<ImageType[]>([])
  const [fileListBase64, setFileListBase64] = useState<ImageType[]>([])
  const [requestImg2ImgSuccess, setRequestImg2ImgSuccess] = useState<boolean>(false)
  const [renderImg2ImgArray, setRenderImg2ImgArray] = useState<RenderImg2ImgArray[]>([])
  const [isRenderedFirst, setIsRenderedFirst] = useState(false)
  const [blockIndex, setBlockIndex] = useState(0)

  const checkConditionNextStep = () => {
    if (fileListBase64?.length <= 0) {
      message.error('画像データをアップロードしてください。')
      return
    }

    if (fileListBase64?.length > 0) {
      const newArray = [
        ...renderImg2ImgArray,
        {
          images: fileListBase64
        }
      ]
      setRenderImg2ImgArray(newArray)
      setFileListBase64([])
    }
  }

  const handleUploadChange = useCallback(
    (info: UploadChangeParam<any>) => {
      const isImage = info?.file.type.startsWith('image/')

      if (!isImage) {
        return
      }

      const newFile: RcFile = info.file.originFileObj

      if (!fileListBase64.some((file) => file?.uid == newFile?.uid) && fileListBase64?.length < MAX_UPLOAD_PICTURE) {
        const reader = new FileReader()
        reader.readAsDataURL(newFile)
        reader.onload = (e: any) => {
          setFileListBase64((prev: any) => {
            if (prev?.length >= MAX_UPLOAD_PICTURE) {
              return prev
            }
            return [
              ...prev,
              {
                uid: newFile?.uid,
                url: e.target.result
              }
            ]
          })
        }
      }
    },
    [fileListBase64]
  )
  const handleUploadChangeNewThread = useCallback(
    (info: UploadChangeParam<any>) => {
      const isImage = info?.file.type.startsWith('image/')

      if (!isImage) {
        return
      }

      const newFile: RcFile = info.file.originFileObj

      if (
        !fileListBase64NewThread.some((file) => file?.uid == newFile?.uid) &&
        fileListBase64NewThread?.length < MAX_UPLOAD_PICTURE
      ) {
        const reader = new FileReader()
        reader.readAsDataURL(newFile)
        reader.onload = (e: any) => {
          setFileListBase64NewThread((prev: any) => {
            if (prev?.length >= MAX_UPLOAD_PICTURE) {
              return prev
            }
            return [
              ...prev,
              {
                uid: newFile?.uid,
                url: e.target.result
              }
            ]
          })
        }
      }
    },
    [fileListBase64NewThread]
  )

  const checkIsDisabled = (): boolean => {
    if (mediaProcessingJobID || jobRunning) {
      return true
    }
    return false
  }

  const handleRequestImg2Img = async (option: number, fileListBase64NewThread: ImageType[]): Promise<void> => {
    setStopLoop(false)
    setRenderFail(false)
    setIsRenderedFirst(true)
    setIsRequestedImg2Img(true)
    setSystemPromptType(option)
    setIsBlockSelectPrompt(true)
    setJobRunning(true)

    const inputDt: RequestImg2ImgType = {
      type: option,
      checkPointSelected: checkpointImg2ImgSelected?.name,
      fileListBase64: fileListBase64NewThread,
      threadId: newThreadID,
      batchSize: batchSizeSelected
    }

    CommonAPIs.requestImg2Img(inputDt)
      .then((res) => {
        setMediaProcessingJobID(res?.job_id)
        setTimeout(() => {
          getMediasProcessing(res?.job_id)
        }, 1200)
      })
      .catch((err) => {
        setJobRunning(false)
        setIsRequestedImg2Img(false)
        setIsBlockSelectPrompt(false)
        setMediaProcessingJobID(0)
        showErrorApiAlert(err)
      })
  }

  const getThreadList = () => {
    Img2ImgAPIs.getThreadList()
      .then((res: IThreadList[]) => {
        setThreadList(res)
        setNewThreadID(res[0]?.id)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const getMediasProcessing = (mediaProcessingJobID: number): void => {
    CommonAPIs.checkStatusImg2ImgJob(mediaProcessingJobID)
      .then((res) => {
        let newDt: GenerateImg2ImgType[] = []

        res?.data?.map((item: GenerateImg2ImgType) => {
          if (item?.images?.length > 0) {
            newDt = [...newDt, item]
          }
        })

        setGeneratedImageList((prev: GenerateImg2ImgType[]) => {
          const existingIDs = new Set(prev?.map((item) => item.id))
          const filteredNewDt = newDt.filter((newItem) => !existingIDs.has(newItem.id))

          return [...prev, ...filteredNewDt]
        })

        if (res?.status == 2 || res?.status == 3) {
          setStopLoop(true)
          setMediaProcessingJobID(0)
          setJobRunning(false)
        }

        if (res?.status == 3) {
          setRequestImg2ImgSuccess(true)
          setTimeout(() => {
            getThreadList()
          }, 300)
        }

        if (res?.status == 2) {
          setRenderFail(true)
          setRenderFailApiMsg(res?.message)
        }
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const handleRequestReGenerate = (promptID: number, prompt: string, batchSize: number) => {
    //if (!checkpointProfile?.profile_jp || checkpointProfile?.profile_jp == '') {
    //  message.error('プロフィールを入力してください。')
    //  return
    //}
    // reset data when call request again
    setStopLoop(false)
    setRenderFail(false)
    setJobRunning(true)

    CommonAPIs.requestReGenerationImg2Img(
      promptID,
      //prompt,
      checkpointImg2ImgSelected?.name,
      batchSize,
      newThreadID
    )
      .then((res) => {
        // showProcessingAlert('画像を成作しています。AIサーバー状況にお時間がかかる場合があります。')
        setMediaProcessingJobID(res?.data?.job_id)
        setTimeout(() => {
          getMediasProcessing(res?.data?.job_id)
        }, 1200)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const onSelectEditSystemPrompt = () => {
    setIsBlockSelectPrompt(false)
  }

  const beforeUpload = (file: RcFile) => {
    const isImage = file.type.startsWith('image/')
    if (!isImage) {
      message.error('アップロードは画像ファイルではありません')
      return
    }
  }

  const removeImageNewThread = (item: ImageType) => {
    const newData = fileListBase64NewThread?.filter((items) => items?.uid != item?.uid)
    setFileListBase64NewThread(newData)
  }

  const removeImage = (item: ImageType) => {
    const newData = fileListBase64?.filter((items) => items?.uid != item?.uid)
    setFileListBase64(newData)
  }

  const onClickRerenderFullFlow = () => {
    handleRequestImg2Img(systemPromptType, fileListBase64NewThread)
  }

  const handleRequestNewThread = (value: number) => {
    setSystemPromptType(value)
    setIsBlockSelectPrompt(true)
    if (isRenderedFirst) {
      handleRequestImg2Img(value, fileListBase64NewThread)
    }
  }

  const handleResetDt = () => {
    setImgUploaded(false)
    setIsRequestedImg2Img(false)
    setSystemPromptType(0)
    setGeneratedImageList([])
    setMediaProcessingJobID(0)
    setIsBlockSelectPrompt(false)
    setRenderFail(false)
    setRequestImg2ImgSuccess(false)
    setJobRunning(false)
    setFileListBase64NewThread([])
    setFileListBase64([])
    setStopLoop(true)
    setIsShowProfileModal(false)
    setRenderFailApiMsg('')
    setRenderImg2ImgArray([])
    setIsRenderedFirst(false)
  }

  const checkUploadMultiImg = () => {
    if (fileListBase64NewThread.length > 1) {
      return true
    }
    return false
  }

  return {
    mediaProcessingJobID,
    setMediaProcessingJobID,
    getMediasProcessing,
    isStopLoop,
    checkIsDisabled,
    handleRequestImg2Img,
    generatedImageList,
    systemPromptType,
    isRequestedImg2Img,
    handleResetDt,
    imgUploaded,
    setImgUploaded,
    isBlockSelectPrompt,
    setIsBlockSelectPrompt,
    onSelectEditSystemPrompt,
    checkpointImg2ImgSelected,
    isShowProfileModal,
    setIsShowProfileModal,
    isRenderFail,
    renderFailApiMsg,
    handleRequestReGenerate,
    handleUploadChangeNewThread,
    beforeUpload,
    removeImageNewThread,
    fileListBase64NewThread,
    checkConditionNextStep,
    renderImg2ImgArray,
    requestImg2ImgSuccess,
    handleUploadChange,
    onClickRerenderFullFlow,
    fileListBase64,
    isRenderedFirst,
    handleRequestNewThread,
    checkUploadMultiImg,
    setSystemPromptType,
    removeImage,
    blockIndex,
    setBlockIndex
  }
}

export default useNewThreadImg2Img
