import { useContext, useState } from 'react'
import { message } from 'antd'
import { AppContext } from './AppContext'
import { CheckpointProfileType } from '../types'
import CommonAPIs from '../controller/API/CommonAPIs'
import { GenerateImg2ImgType, ImageType, RequestImg2ImgType } from '../types/media_type'
import { ThreadImg2ImgHistoryType } from '../types/thread'
import { useThreadStore } from '../controller/store'

const useImg2ImgItem = (
  checkpointProfile: CheckpointProfileType | undefined,
  fileListBase64: ImageType[],
  setJobRunning: (value: boolean) => void,
  jobRunning: boolean,
  threadHistory?: ThreadImg2ImgHistoryType,
  threadSelectedID = 0
) => {
  const { batchSizeSelected, checkpointImg2ImgSelected } = useThreadStore()
  const { showErrorApiAlert, showProcessingAlert } = useContext(AppContext)
  const [generatedImageList, setGeneratedImageList] = useState<GenerateImg2ImgType[]>(
    threadHistory ? threadHistory.prompts : []
  )
  const [mediaProcessingJobID, setMediaProcessingJobID] = useState<number>(0)
  const [isStopLoop, setStopLoop] = useState(false)
  const [systemPromptType, setSystemPromptType] = useState<number>(
    threadHistory ? threadHistory?.system_prompt_type : 0
  )
  const [isRequestedImg2Img, setIsRequestedImg2Img] = useState<boolean>(false)
  const [imgUploaded, setImgUploaded] = useState<boolean>(false)
  const [isBlockSelectPrompt, setIsBlockSelectPrompt] = useState<boolean>(
    threadHistory?.system_prompt_type ? true : false
  )
  const [isShowProfileModal, setIsShowProfileModal] = useState(false)
  const [isRenderFail, setRenderFail] = useState<boolean>(false)
  const [renderFailApiMsg, setRenderFailApiMsg] = useState<string>('')

  const checkIsDisabled = (): boolean => {
    if (mediaProcessingJobID || jobRunning) {
      return true
    }
    return false
  }

  const handleRequestImg2Img = async (option: number): Promise<void> => {
    //if (!checkpointProfile?.profile_jp || checkpointProfile?.profile_jp == '') {
    //  message.error('プロフィールを入力してください。')
    //  return
    //}
    const inputDt: RequestImg2ImgType = {
      type: option,
      checkPointSelected: checkpointImg2ImgSelected?.name,
      fileListBase64: fileListBase64,
      threadId: threadSelectedID,
      batchSize: batchSizeSelected
    }

    // reset data when call request again
    // setGeneratedImageList([])
    setStopLoop(false)
    setRenderFail(false)

    // reset data when success call request again
    setIsRequestedImg2Img(true)
    setSystemPromptType(option)
    setIsBlockSelectPrompt(true)

    setJobRunning(true)

    CommonAPIs.requestImg2Img(inputDt)
      .then((res) => {
        console.log('ress::', res)
        setMediaProcessingJobID(res?.job_id)
        setTimeout(() => {
          getMediasProcessing(res?.job_id)
        }, 1200)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const getMediasProcessing = (mediaProcessingJobID: number): void => {
    CommonAPIs.checkStatusImg2ImgJob(mediaProcessingJobID)
      .then((res) => {
        console.log('processing::', res)
        let newDt: GenerateImg2ImgType[] = []

        res?.data?.map((item: GenerateImg2ImgType) => {
          if (item?.images?.length > 0) {
            newDt = [...newDt, item]
          }
        })

        setGeneratedImageList((prev: GenerateImg2ImgType[]) => {
          const existingIDs = new Set(prev?.map((item) => item.id))
          const filteredNewDt = newDt.filter((newItem) => !existingIDs.has(newItem.id))

          return [...prev, ...filteredNewDt]
        })

        if (res?.status == 2 || res?.status == 3) {
          setStopLoop(true)
          setMediaProcessingJobID(0)
          setJobRunning(false)
        }

        if (res?.status == 2) {
          setRenderFail(true)
          setRenderFailApiMsg(res?.message)
        }
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const handleRequestReGenerate = (promptID: number, batchSize: number) => {
    //if (!checkpointProfile?.profile_jp || checkpointProfile?.profile_jp == '') {
    //  message.error('プロフィールを入力してください。')
    //  return
    //}
    // reset data when call request again
    setStopLoop(false)
    setRenderFail(false)

    CommonAPIs.requestReGenerationImg2Img(
      promptID,
      //prompt,
      checkpointImg2ImgSelected?.name,
      batchSize,
      threadSelectedID
    )
      .then((res) => {
        console.log('rerender:', res)
        setJobRunning(true)
        // showProcessingAlert('画像を成作しています。AIサーバー状況にお時間がかかる場合があります。')
        setMediaProcessingJobID(res?.data?.job_id)
        setTimeout(() => {
          getMediasProcessing(res?.data?.job_id)
        }, 1200)
      })
      .catch((err) => showErrorApiAlert(err))
  }

  const handleResetDt = () => {
    setImgUploaded(false)
    setIsRequestedImg2Img(false)
    setSystemPromptType(1)
    setGeneratedImageList([])
    setMediaProcessingJobID(0)
    setIsBlockSelectPrompt(false)
    setRenderFail(false)
  }

  const onSelectEditSystemPrompt = () => {
    setIsBlockSelectPrompt(false)
  }

  return {
    mediaProcessingJobID,
    setMediaProcessingJobID,
    getMediasProcessing,
    isStopLoop,
    checkIsDisabled,
    handleRequestImg2Img,
    generatedImageList,
    systemPromptType,
    isRequestedImg2Img,
    handleResetDt,
    imgUploaded,
    setImgUploaded,
    isBlockSelectPrompt,
    setIsBlockSelectPrompt,
    onSelectEditSystemPrompt,
    checkpointProfile,
    isShowProfileModal,
    setIsShowProfileModal,
    isRenderFail,
    renderFailApiMsg,
    handleRequestReGenerate
  }
}

export default useImg2ImgItem
