import axios from 'axios'
import BaseAPIs from './BaseAPI'

export default class ControlGPU_APIs extends BaseAPIs {
  static endpoints = {
    onOffRenderingGPU: this.baseURL + '/ai-img/gpu/toggle-rendering-images',
    onOffTrainingGPU: this.baseURL + '/ai-img/gpu/toggle-lora-training',
  }

  static async openGPURenderingImages() {
    const token = localStorage.getItem('token')
    const formData = new FormData()
    formData.append('status', '1')
    const url = `${ControlGPU_APIs.endpoints.onOffRenderingGPU}`

    await axios.post(url, formData, {headers: { ...this.headers, Authorization: 'Bearer ' + token }})
      .then((response) => {
        return Promise.resolve(response.data?.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static async closeGPURenderingImages() {
    const token = localStorage.getItem('token')
    const formData = new FormData()
    formData.append('status', '0')
    const url = `${ControlGPU_APIs.endpoints.onOffRenderingGPU}`

    await axios.post(url, formData, {headers: { ...this.headers, Authorization: 'Bearer ' + token }})
      .then((response) => {
        return Promise.resolve(response.data?.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static async openGPULoraTraining() {
    const token = localStorage.getItem('token')
    const formData = new FormData()
    formData.append('status', '1')
    const url = `${ControlGPU_APIs.endpoints.onOffTrainingGPU}`

    await axios.post(url, formData, {headers: { ...this.headers, Authorization: 'Bearer ' + token }})
      .then((response) => {
        return Promise.resolve(response.data?.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  static async closeGPULoraTraining() {
    const token = localStorage.getItem('token')
    const formData = new FormData()
    formData.append('status', '0')
    const url = `${ControlGPU_APIs.endpoints.onOffTrainingGPU}`

    await axios.post(url, formData, {headers: { ...this.headers, Authorization: 'Bearer ' + token }})
      .then((response) => {
        return Promise.resolve(response.data?.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}