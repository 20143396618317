import { useEffect, useState, useContext } from 'react'

type Prop = {
	openGPU: () => void
	closeGPU: () => void
}

export default function OnOffButtons(prop: Prop) {
	const { openGPU, closeGPU } = prop
	return (
		<div className='flex w-100 justify-center'>
			<button className="w-[200px] bg-green-600 mx-5" onClick={() => openGPU()}>Open GPU</button>
			<button className="w-[200px] bg-red-600 mx-5" onClick={() => closeGPU()}>Close GPU</button>
		</div>
	)
}