import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Carousel, Col, Flex, Image, List, Modal, Row, Space } from 'antd'
import type { CarouselRef } from 'antd/es/carousel'
import {
  BorderOutlined,
  CheckSquareOutlined,
  CloseOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  RightCircleOutlined,
  RightOutlined
} from '@ant-design/icons'
import { AppContext } from '../../../hooks/AppContext'
import { saveAs } from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import DownloadIcon from '../../../assets/ic-download.svg'

type Props = {
  imageList: { url: string }[]
  indexPreview: number
  isPreview: boolean
  setIsPreview: (value: boolean) => void
}

const PreviewImagesModal: React.FC<Props> = (props) => {
  const { imageList, setIsPreview, isPreview, indexPreview } = props
  const { isMobile, showErrorApiAlert } = useContext(AppContext)
  const carouselRef = useRef<CarouselRef | null>()
  const handleClose = () => setIsPreview(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [imgsSelected, setImgsSelected] = useState<{ url: string }[]>([])

  const onPreviousCarouselClick = () => {
    if (currentIndex == 0) {
      setCurrentIndex(imageList.length - 1)
      return
    }
    setCurrentIndex(currentIndex - 1)
  }

  const onNextCarouselClick = () => {
    console.log('=========: ', currentIndex)
    if (currentIndex == imageList.length - 1) {
      setCurrentIndex(0)
      return
    }
    setCurrentIndex(currentIndex + 1)
  }

  const handleDownloadImage = async (imageUrl: string) => {
    try {
      const response = await fetch(imageUrl + '?not-from-cache-please')
      const blob = await response.blob()

      const fileName = uuidv4()
      saveAs(blob, fileName)
    } catch (error) {
      showErrorApiAlert(error)
    }
  }

  const handleDownloadListImg = (item: { url: string }[]) => {
    for (let index = 0; index < item?.length; index++) {
      handleDownloadImage(item[index]?.url)
    }
  }

  const handleAfterChange = (current: number) => {
    setCurrentIndex(current)
  }

  useEffect(() => {
    setCurrentIndex(indexPreview)
  }, [indexPreview])

  return (
    <>
      {isPreview && (
        <Flex vertical id='myModal' className='modal' justify='center' align='center' style={{ zIndex: 999 }}>
          <Flex
            style={{
              background: 'white',
              width: '73%',
              maxHeight: '90%',
              borderRadius: 9,
              overflow: 'scroll'
            }}
            vertical
          >
            <CloseOutlined
              style={{
                marginTop: 16,
                fontSize: 24,
                alignSelf: 'flex-end',
                marginRight: 16
              }}
              onClick={handleClose}
            />
            <Flex style={{ alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
              <LeftOutlined
                onClick={onPreviousCarouselClick}
                style={{
                  marginRight: 8
                }}
              />
              <Image
                src={imageList[currentIndex]?.url}
                style={{ height: 434, width: 434, objectFit: 'contain' }}
                preview={false}
              />
              <RightOutlined
                onClick={onNextCarouselClick}
                style={{
                  marginLeft: 8
                }}
              />
            </Flex>
            <Row style={{ display: 'flex', justifyContent: 'center', marginTop: 30, width: '100%' }}>
              <div style={{ 
                width: '428px',
                overflowX: 'auto',
                display: 'flex',
                justifyContent: 'flex-start',
                padding: '0 8px',
                scrollBehavior: 'smooth'
              }}>
                <div style={{ 
                  display: 'inline-flex',
                  gap: '8px',
                  margin: '0 auto',
                }}>
                  {imageList.map((item, index) => (
                    <div 
                      key={item.url} 
                      className="relative" 
                      style={{ 
                        flexShrink: 0,
                        width: 136,
                        opacity: currentIndex === index ? 1 : 0.6,
                        transition: 'opacity 0.3s'
                      }}
                      onClick={() => setCurrentIndex(index)}
                    >
                      <img
                        className='cursor-pointer hover:bg-[#d7d7d7] absolute top-[6px] right-[8px]'
                        src={DownloadIcon}
                        style={{
                          width: 25,
                          height: 25,
                          padding: 3,
                          borderRadius: '50%',
                          zIndex: 1
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownloadImage(item.url);
                        }}
                      />
                      <img
                        draggable={false}
                        style={{
                          width: 136,
                          height: 136,
                          borderRadius: 8,
                          objectFit: 'cover',
                          cursor: 'pointer'
                        }}
                        src={item?.url}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Row>
            <Row style={{ justifyContent: 'center', marginTop: 8, marginBottom: 20 }}>
              <Button
                onClick={() => handleDownloadListImg(imageList)}
                type='primary'
                className='w-[256px] h-[40px] font-bold bg-[#323539] mb-3 mt-3'
              >
                画像を一括保存
              </Button>
            </Row>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default PreviewImagesModal
