import { Layout } from 'antd'
import { useContext, useEffect } from 'react'
import OnOffButtons from './components/Buttons'
import Constant from '../../controller/Constant'
import { AppContext } from '../../hooks/AppContext'
import { useThreadStore } from '../../controller/store'
import ControlGPU_APIs from '../../controller/API/controlGPU_APIs'

export default function ControlGPUScreen() {
	const { setScreenKey } = useThreadStore()
	const { showProcessingAlert, showErrorApiAlert } = useContext(AppContext)

	const openGPURenderingImages = () => {
		if (window.confirm('Are you sure you want to open rendering GPU?')) {
			ControlGPU_APIs.openGPURenderingImages()
				.then(() => showProcessingAlert('The request to initialize the GPU for rendering images has been sent successfully. It will take a few minutes for the GPU to complete the initialization process.'))
				.catch((error: any) => {
					console.log(error)
					showErrorApiAlert('Failed to open GPU !!!')
				})
		}
	}
	const closeGPURenderingImages = () => {
		if (window.confirm('Are you sure you want to close rendering GPU?')) {
			ControlGPU_APIs.closeGPURenderingImages()
				.then(() => showProcessingAlert('GPU shutdown request sent successfully.'))
				.catch((error: any) => {
					console.log(error)
					showErrorApiAlert('Failed to close GPU !!!')
				})
		}
	}

	const openGPULoraTraining = () => {
		if (window.confirm('Are you sure you want to open GPU for lora training?')) {
			ControlGPU_APIs.openGPULoraTraining()
				.then(() => showProcessingAlert('The request to initialize the GPU for lora training has been sent successfully. It will take a few minutes for the GPU to complete the initialization process.'))
				.catch((error: any) => {
					console.log(error)
					showErrorApiAlert('Failed to open GPU !!!')
				})
		}
	}
	const closeGPULoraTraining = () => {
		if (window.confirm('Are you sure you want to close GPU for lora training?')) {
			ControlGPU_APIs.closeGPULoraTraining()
				.then(() => showProcessingAlert('GPU shutdown request sent successfully.'))
				.catch((error: any) => {
					console.log(error)
					showErrorApiAlert('Failed to close GPU !!!')
				})
		}
	}

	useEffect(() => {
		setScreenKey(Constant.keyScreen.controlGpu)
	}, [])

	return (
		<div className='flex'>
			<Layout>
				<h2 className='text-center mt-10'>GPU for rendering images</h2>
				<OnOffButtons openGPU={openGPURenderingImages} closeGPU={closeGPURenderingImages} />
				<h2 className='text-center mt-20'>GPU for lora training</h2>
				<OnOffButtons openGPU={openGPULoraTraining} closeGPU={closeGPULoraTraining} />
			</Layout>
		</div>
	)
}