import { useContext, useEffect, useState } from 'react'
import { Input, Row, Typography, Layout, Modal, Flex, Upload, Space, Image } from 'antd'
import { AppContext } from '../../hooks/AppContext'
import Constant from '../../controller/Constant'
import { ImageType } from '../../types'
import { CloseCircleOutlined, FolderOutlined } from '@ant-design/icons'
import useImg2Img from '../../hooks/useImg2Img'
import RenderFullFlowItem from './RenderFullFlowItem'
import UploadImg2Img from './components/UploadImg2Img'
import Introduction from './components/Introduction'
import { useThreadStore } from '../../controller/store'
import { ThreadImg2ImgHistoryType } from '../../types/thread'
import { v4 as uuidv4 } from 'uuid'
import Sider from 'antd/es/layout/Sider'
import Img2ImgSidebar from './components/Img2ImgSidebar'
import NewThreadStepsItem from './NewThreadStepsItem'

const Img2ImgScreen = () => {
  const { setMenuSelected, windowWidth, windowHeight } = useContext(AppContext)
  const [jobRunning, setJobRunning] = useState<boolean>(false)
  const [blockIndex, setBlockIndex] = useState<number>(0)
  const [newBlockIndex, setNewBlockIndex] = useState<number>(0)

  const {
    setScreenKey,
    threadSelectedID,
    threadList,
    setCheckpointImg2ImgSelected,
    setNewThreadID,
    setThreadSelectedID,
    countNewThread,
    setBatchSizeSelected
  } = useThreadStore()

  const {
    checkpointProfile,
    checkpointProfileEdited,
    setCheckpointProfileEdited,
    updateCheckpointProfile,
    isShowProfileModal,
    handleCancelSaveProfile,
    renderImg2ImgArray,
    setRenderImg2ImgArray,
    handleUploadChange,
    beforeUpload,
    fileListBase64,
    checkConditionNextStep,
    getThreadContentHistory,
    threadHistory,
    getThreadList,
    setCheckpointProfile,
    setThreadHistory,
    removeImage,
    checkShowIntroduction,
    isShowIntroduction
  } = useImg2Img()

  useEffect(() => {
    if (threadSelectedID) {
      setRenderImg2ImgArray([])
      setJobRunning(false)
      getThreadContentHistory(threadSelectedID)
    }
  }, [threadSelectedID])

  useEffect(() => {
    setCheckpointImg2ImgSelected({ name: '', first_image: '' })
    setCheckpointProfile({
      id: 0,
      checkpoint: '',
      profile_en: '',
      profile_jp: ''
    })
    setNewThreadID(0)
    setThreadSelectedID(0)
    setThreadHistory([])
    setBatchSizeSelected(1)
  }, [countNewThread])

  useEffect(() => {
    getThreadList()
    setScreenKey(Constant.keyScreen.img2img)
    setMenuSelected(Constant.keyScreen.img2img)
    checkShowIntroduction()
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    })
  }, [threadHistory])
  
  useEffect(() => {
    if (!jobRunning) {
      setBlockIndex(0)
      setNewBlockIndex(0)
    }
  }, [jobRunning])

  return (
    <Flex className='bg-[#f5f5f5]'>
      <Img2ImgSidebar jobRunning={jobRunning} />
      <Layout className='mb-10 h-full pb-2 items-center'>
        {!threadSelectedID ? (
          <NewThreadStepsItem jobRunning={jobRunning} setJobRunning={setJobRunning} />
        ) : (
          <>
            <Flex
              className='my-7'
              align='center'
              style={{
                width: windowWidth * (605 / 1440)
              }}
            >
              <img src={Constant.icon.bot} className='h-[44px] aspect-square mr-5' />
              <Typography.Text className='text-[20px] font-bold'>
                カテゴリーと画像生成枚数を設定してください
              </Typography.Text>
            </Flex>
            {threadHistory?.map((item: ThreadImg2ImgHistoryType, index) => {
              const imgsHistory: ImageType[] = item?.original_media?.map((item) => {
                return {
                  uid: uuidv4(),
                  url: item
                }
              })
              return (
                <RenderFullFlowItem
                  key={item?.id}
                  fileListBase64={imgsHistory}
                  isItemLast={index + 1 == threadHistory?.length + renderImg2ImgArray?.length}
                  setJobRunning={setJobRunning}
                  checkpointProfile={checkpointProfile}
                  jobRunning={jobRunning}
                  isHistory={true}
                  threadHistory={item}
                  threadSelectedID={threadSelectedID}
                  blockIndex={blockIndex}
                  index={index}
                  setBlockIndex={setBlockIndex}
                />
              )
            })}

            {renderImg2ImgArray?.map((item, index) => (
              <RenderFullFlowItem
                key={index}
                fileListBase64={item?.images}
                isItemLast={index + 1 + threadHistory?.length == threadHistory?.length + renderImg2ImgArray?.length}
                checkpointProfile={checkpointProfile}
                setJobRunning={setJobRunning}
                jobRunning={jobRunning}
                threadSelectedID={threadSelectedID}
                blockIndex={newBlockIndex}
                index={index}
                setBlockIndex={setNewBlockIndex}
              />
            ))}
            {(threadHistory.length > 0 || renderImg2ImgArray.length > 0) && (
              <>
                <Row className='h-[1px] bg-[#D3D3D3] mt-[45px] ' />
                <Flex
                  className='mt-7 bg-white w-[80%] text-center content-center rounded-lg pr-5 pl-5 pt-6 pb-6 self-center'
                  justify='space-between'
                  align='center'
                  style={{ backgroundColor: jobRunning ? '#C4C4C4' : 'white', width: windowWidth * (605 / 1440) }}
                >
                  <Typography className='w-8' />
                  <Flex vertical justify='center' align='center'>
                    <Space className='mb-4 flex flex-wrap'>
                      {fileListBase64?.map((item: ImageType) => (
                        <Flex key={item?.uid} className='ml-1 mr-1 mb-4 relative'>
                          <Image src={item?.url} width='auto' height={100} />
                          <img
                            src={Constant.icon.closeTransparent}
                            className='w-4 aspect-square absolute text-white right-[2px] top-[2px] cursor-pointer z-50 object-cover'
                            onClick={() => removeImage(item)}
                          />
                        </Flex>
                      ))}
                    </Space>
                    <Upload
                      listType='picture'
                      onChange={handleUploadChange}
                      name='avatar'
                      className='avatar-uploader '
                      showUploadList={false}
                      multiple
                      beforeUpload={beforeUpload}
                      openFileDialogOnClick={jobRunning ? false : true}
                      disabled={jobRunning ? true : false}
                    >
                      <Flex className='cursor-pointer'>
                        <FolderOutlined
                          className='text-[20px] mr-2'
                          style={{ color: jobRunning ? 'white' : 'black' }}
                        />
                        <Typography
                          className='text-[18px] overflow-hidden whitespace-nowrap text-ellipsis'
                          style={{ color: jobRunning ? 'white' : 'black' }}
                        >
                          画像をアップロード
                        </Typography>
                      </Flex>
                    </Upload>
                  </Flex>
                  <img
                    className='text-[25px] ml-2 w-[25px] aspect-square cursor-pointer'
                    src={require('../../assets/up.png')}
                    onClick={() => {
                      // (jobRunning ? undefined : checkConditionNextStep())
                      if (!jobRunning) {
                        checkConditionNextStep()
                        // window.scrollTo({
                        //   top: document.body.scrollHeight,
                        //   behavior: 'smooth'
                        // })
                      }
                    }}
                  />
                </Flex>
                <Modal
                  title='プロフィール'
                  open={isShowProfileModal}
                  onOk={updateCheckpointProfile}
                  onCancel={handleCancelSaveProfile}
                  cancelText='キャンセル'
                  okText='保存する'
                  closeIcon={<CloseCircleOutlined className='text-black' />}
                >
                  <Input value={checkpointProfileEdited} onChange={(e) => setCheckpointProfileEdited(e.target.value)} />
                </Modal>
              </>
            )}
          </>
        )}
        {isShowIntroduction && <Introduction />}
      </Layout>
    </Flex>
  )
}

export default Img2ImgScreen
